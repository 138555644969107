import React from 'react'
import styled from 'styled-components'

// elements
import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'
import Pagination from '../../elements/pagination'

// Compositions
import EventCard from '../../compositions/eventCard'
import FilterList from '../../compositions/filterListModal'

import CalendarModal from './calendarModal'

const Wrapper = styled.div`
  .bar {
    display: flex;
    align-items: center;
    padding: 26px 0;
    h2 {
      padding: 0;
      flex: 1;
    }
    .options {
      > * {
        margin-left: 16px;
      }
    }
  }
  .events {
    position: relative;
    width: 100%;

    .card {
      display: inline-block;
      width: calc(100% / 3);
      padding-bottom: 30px;
      :nth-child(3n + 1) {
        padding-right: 20px;
      }
      :nth-child(3n + 2) {
        padding-right: 10px;
        padding-left: 10px;
      }
      :nth-child(3n + 3) {
        padding-left: 20px;
      }

      @media screen and (max-width: 740px) {
        width: 100%;
        padding: 0 !important;
        margin-bottom: 20px !important;
      }
    }
    .modal {
      position: absolute;
      width: 100%;
      z-index: 2;
      top: 0;
      left: 0;
      box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.2);

      @media screen and (max-width: 700px) {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 99999;
        background: #fff;
      }

      .closeButton {
        position: absolute;
        right: 35px;
        top: 29px;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .calendar-modal {
      position: absolute;
      z-index: 2;
      top: 2%;
      right: 2%;
      margin: 0px 0 0 -250px;
      box-shadow: 0px 8px 22px rgba(0, 0, 0, 0.2);

      .closeButton {
        position: absolute;
        right: 30px;
        top: 34px;
        font-weight: bold;
        cursor: pointer;
      }

      @media screen and (max-width: 700px) {
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 999999;
      }
    }
  }

  .selectedfilters {
    padding: 0px 8px;
    background: #2e842a;
    color: #fff;
    margin: 0 0 0 8px;
    border-radius: 6px;
  }

  .pagination {
    display: inline-block;
    float: right;
  }
`

const EventResultsList = ({
  events,
  totalResults,
  filters,
  isShort = false,
  shortTitle = '',
  handleDateChange,
  handleFilterChange,
  handleCategorySelection,
  selectedSports,
  selectedCategory,
  page,
  pages,
  handlePageChange,
}) => {
  const [showdate, setShowdate] = React.useState(false)
  const [showfilters, setShowfilters] = React.useState(false)
  const [selectionRange, setSelectionRange] = React.useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const handleDateModal = () => {
    setShowdate(!showdate)
    setShowfilters(false)
  }

  const handleFiltersModal = () => {
    setShowdate(false)
    setShowfilters(!showfilters)
  }

  const handleDateSelection = (selection) => {
    handleDateChange(selection)
  }

  const handleFilterSelection = (filter) => {
    handleFilterChange(filter)
  }

  return (
    <Wrapper>
      <div className="bar">
        {isShort ? (
          <TypographyElement variant="subtitle" component="h2">
            {shortTitle}
          </TypographyElement>
        ) : (
          <>
            <TypographyElement variant="subtitle" component="h2">
              {totalResults} resultaten
            </TypographyElement>
            <div className="options">
              <ButtonElement
                variant="iconButton"
                icon="calendar"
                handleClick={handleDateModal}
              >
                Kies een datum
              </ButtonElement>
              <ButtonElement
                variant="iconButton"
                icon="filter"
                handleClick={handleFiltersModal}
              >
                Filter op sport
                <span className="selectedfilters">
                  {selectedSports?.length + (selectedCategory ? 1 : 0) || 0}
                </span>
              </ButtonElement>
            </div>
          </>
        )}
      </div>
      <div className="events">
        {events?.map((item, index) => {
          if (isShort && index > 2) {
            return null
          }
          return (
            <div key={`card-event-ind-${index}`} className="card">
              <EventCard {...item} />
            </div>
          )
        })}
        {pages > 0 && events?.length > 0 && (
          <div className="pagination">
            <Pagination
              count={pages}
              handleChange={handlePageChange}
              page={page}
            />
          </div>
        )}
        {showfilters && (
          <div className="modal">
            <div className="closeButton" onClick={() => setShowfilters(false)}>
              X
            </div>
            <FilterList
              title="Sporten"
              maxColumn={15}
              filters={filters}
              selectedCategory={selectedCategory}
              selectedSports={selectedSports}
              handleFilterSelection={handleFilterSelection}
              handleCategorySelection={handleCategorySelection}
            />
          </div>
        )}
        {showdate && (
          <div className="calendar-modal">
            <div className="closeButton" onClick={() => setShowdate(false)}>
              X
            </div>
            <CalendarModal
              selectionRange={selectionRange}
              setSelectionRange={setSelectionRange}
              handleDateSelection={handleDateSelection}
            />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default EventResultsList
