import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'
import ButtonElement from '../../elements/buttons'
import { grey, lightgrey } from '../../colors'

const Wrapper = styled.div`
  padding: 48px 38px;
  background-color: ${lightgrey};
  border: 1px solid ${grey};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .text {
    margin-right: 20px;
  }
  .button {
    text-align: right;

    @media screen and (max-width: 600px) {
      svg {
        display: none;
      }
    }
  }

  @media screen and (max-width: 740px) {
    display: block;

    .text {
      padding-bottom: 24px;
    }
  }
`

const TextCta = ({ text, link }) => {
  if (!link) {
    return null
  }
  return (
    <Wrapper>
      <div className="text">
        <TypographyElement variant="ctaInlineText" component="h2">
          {text}
        </TypographyElement>
      </div>
      <div className="button">
        <ButtonElement variant="primary" href={link?.href}>
          {link?.text}
        </ButtonElement>
      </div>
    </Wrapper>
  )
}

export default TextCta
