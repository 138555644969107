import React from 'react'
import styled from 'styled-components'
import ButtonElement from '../../elements/buttons'
import ImageBuilder from '../../elements/imageBuilder'
import TypographyElement from '../../elements/typography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { black, blue, event_border_grey, white } from '../../colors'
import DateBlock from '../redDateBlock'
import { getDayMonth } from '../../utils/getDateInfo'
import { getMomentTz } from '../../utils/getMomentTz'

const Wrapper = styled.div`
  width: 100%;
  /* max-width: 422px; */
  border: 2px solid ${event_border_grey};

  > span {
    display: block;
  }
  .imageContainer {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: -4px;
    > img {
      width: 100% !important;
      aspect-ratio: 1.6;
      object-position: center;
      object-fit: cover;
    }
    .dateContainer {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .textContainer {
    display: block;
    padding: 26px 34px 27px;
    border-top: 0;
    position: relative;
    .linkblock {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 72px;
      margin-top: 8px;
      > * {
        display: inline;
        vertical-align: middle;
        padding: 0;
      }

      > svg {
        margin-left: 20px;
        path {
          stroke: ${black};
        }
      }
    }
    .categoryContainer {
      position: absolute;
      top: 0;
      right: 25px;
      padding: 2px 15px;
      background-color: ${blue};
      border-radius: 62px;
      transform: translateY(-50%);
      span {
        color: ${white};
        font-family: 'Dosis';
        font-weight: 600;
        font-size: 12px;
        line-height: 1.5;
        text-transform: uppercase;
      }
    }
  }
`

const buildEventEndDate = (event_start, event_end) => {
  const startDate = getMomentTz(event_start)
  const startDay = startDate.format('dddd')
  const startDayNr = startDate.format('D')
  const startMonth = startDate.format('MMMM')
  const startMonthShort = startDate.format('MMM')
  const startTime = getMomentTz(event_start).format('HH:mm')

  let endDate = event_end ? getMomentTz(event_end) : null
  const singleDay = endDate?.isAfter(startDate, 'day')
  let fullDate
  if (endDate && singleDay) {
      const endDay = endDate.format('dddd')
      const endDayNr = endDate.format('D')
      const endMonth = endDate.format('MMMM')
      fullDate = `${startDay} ${startDayNr} ${startMonth} t/m ${endDay} ${endDayNr} ${endMonth}`
  } else {
      fullDate = `${startDay} ${startDayNr} ${startMonth} om ${startTime}u`
  }

  return fullDate
}

const EventCard = ({
  title,
  href,
  url,
  img,
  search_img,
  time,
  event_start,
  event_end,
  category,
}) => {
  const { day, month } = getDayMonth(event_start)
  const link = href || url || {}

  const image = search_img || img
  const endDateString = buildEventEndDate(event_start, event_end)
  return (
    <Wrapper>
      <ButtonElement variant="base" href={link}>
        <div className="imageContainer">
          <ImageBuilder image={image} width={422} height={264} />
          <div className="dateContainer">
            <DateBlock day={day} month={month} />
          </div>
        </div>
        <div className="textContainer">
          {category && (
            <div className="categoryContainer">
              <span>{category}</span>
            </div>
          )}
          <TypographyElement variant="eventBlockSubtitle" component="span">
            {endDateString || time}
          </TypographyElement>
          <div className="linkblock">
            <TypographyElement variant="searchSiteTitle" component="span">
              {title}
            </TypographyElement>
            <ArrowForwardIosIcon width={12} height={22} />
          </div>
        </div>
      </ButtonElement>
    </Wrapper>
  )
}

export default EventCard
