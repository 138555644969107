import React from 'react'
import styled from 'styled-components'
import { red } from '../../colors'

import TypographyElement from '../../elements/typography'

const Block = styled.div`
  padding: 8px 0;
  background: ${red};
  width: 95px;
  height: 95px;
  > * {
    display: block;
    :last-child {
      margin-top: -6px;
    }
  }
`

const DateBlock = ({ day, month }) => {
  return (
    <Block>
      <TypographyElement variant="dateDay">{day}</TypographyElement>
      <TypographyElement variant="dateMonth">{month}</TypographyElement>
    </Block>
  )
}

export default DateBlock
