import React from 'react'
import styled from 'styled-components'
import { secondary_green, white } from '../../colors'

import TypographyElement from '../../elements/typography'
import ListItemElement from '../../elements/listitem'
import Field from '../../elements/fields'

const Container = styled.div`
  width: 100%;
  border: 1px solid #e3e3e3;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media screen and (max-width: 700px) {
    display: block;
    height: 100%;
  }

  .mainfilters {
    /* display: inline-block;
        vertical-align: top; */
    width: 280px;
    background: #e4eaef;
    padding: 21px 34px;
    h2 {
      padding-bottom: 12px;
    }
    .options {
      margin-left: 14px;
    }

    @media screen and (max-width: 700px) {
      width: 100%;

      .options {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        height: 40px;
        overflow-x: scroll;

        li {
          display: inline-block;
          width: auto;
        }
      }
    }
  }
  .subfilters {
    /* display: inline-block;
        vertical-align: top; */
    width: calc(100% - 280px);
    background: ${white};
    padding: 28px 43px;

    @media screen and (max-width: 700px) {
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: scroll;
    }

    .suboptions {
      padding: 11px 16px;
      .column {
        display: inline-block;
        vertical-align: top;
        width: 33%;

        @media screen and (max-width: 1000px) {
          width: 50%;
        }

        @media screen and (max-width: 700px) {
          width: 100%;
        }
        > * {
          line-height: 2;
          span {
            font-weight: 500;
          }
        }
      }
    }
  }
`

const FilterList = ({
  title,
  filters,
  maxColumn = 15,
  selectedSports,
  handleFilterSelection,
  handleCategorySelection,
  selectedCategory,
}) => {
  const handleMainClick = (item) => {
    handleCategorySelection(item)
  }

  let firstColumn = []
  let secondColumn = []
  let thirdColumn = []

  selectedCategory?.subfilters?.length > 0 &&
    selectedCategory.subfilters.forEach((item, index) => {
      if (index < maxColumn) {
        firstColumn.push(item)
      } else if (index < 2 * maxColumn) {
        secondColumn.push(item)
      } else if (index < 3 * maxColumn) {
        thirdColumn.push(item)
      }
    })

  const subfilterSelected = (item) => {
    return selectedSports.some((sport) => sport.id == item.id)
  }

  const handleSubfilter = (item) => {
    handleFilterSelection(item)
  }

  return (
    <Container>
      <div className="mainfilters">
        <TypographyElement variant="subtitle">{title}:</TypographyElement>
        <div className="options">
          {filters?.map((item, index) => {
            return (
              <ListItemElement
                key={`options-list-item-linl-ele-${index}`}
                handleClick={() => {
                  handleMainClick(item)
                }}
                text={item.text}
                variant="uppercase"
                selected={selectedCategory?.text == item.text}
              />
            )
          })}
        </div>
      </div>
      <div className="subfilters">
        {selectedCategory && (
          <>
            <TypographyElement variant="secondaryButtonText">
              {selectedCategory.name}
            </TypographyElement>
            <div className="suboptions">
              <div className="column">
                {firstColumn?.map((sub) => {
                  return (
                    <Field
                      key={sub.text}
                      variant="checkbox"
                      checkColor={secondary_green}
                      id={`subfilter_${sub.id}`}
                      handleChange={() => handleSubfilter(sub)}
                      checked={subfilterSelected(sub)}
                      label={sub.text}
                    />
                  )
                })}
              </div>
              <div className="column">
                {secondColumn?.map((sub) => {
                  return (
                    <Field
                      key={sub.text}
                      variant="checkbox"
                      checkColor={secondary_green}
                      id={`subfilter_${sub.id}`}
                      handleChange={() => handleSubfilter(sub)}
                      checked={subfilterSelected(sub)}
                      label={sub.text}
                    />
                  )
                })}
              </div>
              <div className="column">
                {thirdColumn?.map((sub) => {
                  return (
                    <Field
                      key={sub.text}
                      variant="checkbox"
                      checkColor={secondary_green}
                      id={`subfilter_${sub.id}`}
                      handleChange={() => handleSubfilter(sub)}
                      checked={subfilterSelected(sub)}
                      label={sub.text}
                    />
                  )
                })}
              </div>
            </div>
          </>
        )}

        {!selectedCategory && (
          <div className="nofilters">Selecteer een sportcategorie</div>
        )}
      </div>
    </Container>
  )
}

export default FilterList
