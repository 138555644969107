import React from 'react'
import styled from 'styled-components'

import CustomMonthDateRangePicker from '../../elements/dateRangePicker'
import ButtonElement from '../../elements/buttons'

const Wrapper = styled.div`
  width: 451px;
  border: 1px solid #e3e3e3;
  .buttons {
    display: block;
    padding: 20px 26px;
    background-color: #e4eaef;
    > * {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    height: 100%;
    background: #fff;
  }
`

const CalendarModal = ({
  handleDateSelection = () => {},
  selectionRange,
  setSelectionRange,
}) => {
  const handleSelection = (selection) => {
    handleDateSelection(selection)
  }

  const handleToday = () => {
    const selection = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }
    setSelectionRange(selection)
    handleDateSelection(selection)
  }
  const handleTomorrow = () => {
    let tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const selection = {
      startDate: tomorrow,
      endDate: tomorrow,
      key: 'selection',
    }
    setSelectionRange(selection)
    handleDateSelection(selection)
  }
  const handleWeekend = () => {
    const today = new Date()
    const first = today.getDate() - today.getDay() + 1
    const last = first + 6
    const friday_nr = last - 2

    const friday = new Date(today.setDate(friday_nr))
    const sunday = new Date(today.setDate(last))

    const _today = new Date()
    const start = friday >= _today ? friday : _today
    const selection = {
      startDate: start,
      endDate: sunday,
      key: 'selection',
    }
    setSelectionRange(selection)
    handleDateSelection(selection)
  }

  return (
    <Wrapper>
      <div className="buttons">
        <ButtonElement variant="blank" handleClick={handleToday}>
          Vandaag
        </ButtonElement>
        <ButtonElement variant="blank" handleClick={handleTomorrow}>
          Morgen
        </ButtonElement>
        <ButtonElement variant="blank" handleClick={handleWeekend}>
          Dit weekend
        </ButtonElement>
      </div>
      <div className="calendar">
        <CustomMonthDateRangePicker
          doubleCalendar={false}
          focusDate={new Date()}
          minDate={new Date()}
          showDateDisplay={false}
          handleSelection={handleSelection}
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
        />
      </div>
    </Wrapper>
  )
}

export default CalendarModal
